<template>
  <div class="sticky">
    <div class="grid place-content-center">
      <div class="py-10 lg:max-w-[1500px]">
        <div
          class="lg:grid lg:grid-cols-3 lg:place-content-center xl:mx-70 lg:mx-20 mb-10"
        >
          <div class="mt-10">
            <MainLogo :footer="true" />
          </div>

          <div>
            <div class="mt-10">
              <div v-for="item of menu" :key="item.title">
                <h2
                  :class="{
                    'text-left': true,
                    'text-[22px] font-[\'IBMBold\']': item.type === 'big',
                    'text-[16px] font-[\'IBMBold\']': item.type === 'small',
                  }"
                >
                  <router-link :to="item.link" v-if="item.internal"
                    >{{ item.title }}
                  </router-link>
                  <a :href="item.link" target="blank" v-else>{{
                    item.title
                  }}</a>
                </h2>
              </div>
            </div>
          </div>

          <div class="lg:mx-10 mt-10">
            <!-- isso tem que ver se é hml ou se é produção -->
            <a
              class="text-white bg-[#5095FD] text-[20px] font-['IBMBold'] px-32 py-2 rounded-md font-bold"
              :href="this.clientPanel"
              target="blank"
              >Entrar</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from "@/components/MainLogo.vue";
export default {
  components: {
    MainLogo,
  },
  data() {
    return {
      menu: [
        {
          title: "Quem Somos",
          type: "big",
          link: "/quem-somos",
          internal: true,
        },
        { title: "Clientes", type: "big", link: "/empresas", internal: true },
        {
          title: "Termos de Uso",
          type: "small",
          link: "/termos-de-uso",
          internal: true,
        },
        {
          title: "Política de Privacidade",
          type: "small",
          link: "/politica-de-privacidade",
          internal: true,
        },
        {
          title: "Política de Cookies",
          type: "small",
          link: "/politica-de-cookies",
          internal: true,
        },
        {
          title: "Canal de Comunicação",
          type: "small",
          link:
            this.environment === "hml"
              ? "/empresas/634fd0bfa90090e08e91fb9b"
              : "/empresas/60f0afde65125e0ebaeac18a",
          internal: true,
        },
      ],
    };
  },
};
</script>
