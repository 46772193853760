<template>
  <div></div>
  <div class="flex flex-col min-h-screen">
    <MainHeader />
    <router-view class="flex-grow" />
    <MainFooter class="bg-white" />
  </div>

  <WhatsAppIcon class="animate-bounce" />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import WhatsAppIcon from "@/components/WhatsAppIcon.vue";

export default {
  components: {
    MainHeader,
    MainFooter,
    WhatsAppIcon,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
