const HomeView = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/HomeView.vue");
const RequestDataView = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/RequestDataView.vue");
const PricesView = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/PricesView.vue");
const ContactView = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/ContactView.vue");
const WhoWeAreView = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/WhoWeAreView.vue");
const CompaniesView = () =>
  import(
    /* webpackChunkName: "empresas" */ "../views/companies/CompaniesView.vue"
  );
const CompaniesIdView = () =>
  import(
    /* webpackChunkName: "empresas" */ "../views/companies/CompaniesIdView.vue"
  );
const TermsOfUseView = () =>
  import(/* webpackChunkName: "politica" */ "../views/TermsOfUseView.vue");
const PrivacyPolicyView = () =>
  import(/* webpackChunkName: "politica" */ "../views/PrivacyPolicyView.vue");
const CookiePolicyView = () =>
  import(/* webpackChunkName: "politica" */ "../views/CookiePolicyView.vue");
const EmailSendedView = () =>
  import(/* webpackChunkName: "email" */ "../views/EmailSendedView.vue");
const SellView = () =>
  import(/* webpackChunkName: "email" */ "../views/SellView.vue");

const EBookView = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/EBookView.vue");

const PaymentStep1 = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/PaymentStep1.vue");

const PaymentStep2 = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/PaymentStep2.vue");

  const PaymentStep3 = () =>
  import(/* webpackChunkName: "bcompliance" */ "../views/PaymentStep3.vue");

export const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/solicitacao-dos-titulares",
    name: "solicitacao-dos-titulares",
    component: RequestDataView,
  },
  {
    path: "/precos",
    name: "precos",
    component: PricesView,
  },
  {
    path: "/contato",
    name: "contato",
    component: ContactView,
  },
  {
    path: "/quem-somos",
    name: "quem-somos",
    component: WhoWeAreView,
  },
  {
    path: "/empresas",
    name: "empresas",
    component: CompaniesView,
  },
  {
    path: "/empresas/:id",
    name: "empresas-id",
    component: CompaniesIdView,
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: "/termos-de-uso",
    name: "termos-de-uso",
    component: TermsOfUseView,
  },
  {
    path: "/politica-de-privacidade",
    name: "politica-de-privacidade",
    component: PrivacyPolicyView,
  },
  {
    path: "/politica-de-cookies",
    name: "politica-de-cookies",
    component: CookiePolicyView,
  },
  {
    path: "/email-enviado",
    name: "email-enviado",
    component: EmailSendedView,
  },
  {
    path: "/sucesso",
    name: "sucesso",
    component: SellView,
  },
  {
    path: "/pagamento/passo-1",
    name: "pagamento-passo-1",
    component: PaymentStep1,
  },
  {
    path: "/pagamento/passo-2",
    name: "pagamento-passo-2",
    component: PaymentStep2,
  },
  {
    path: "/pagamento/passo-3",
    name: "pagamento-passo-3",
    component: PaymentStep3,
  },
  {
    path: "/e-book",
    name: "e-book",
    component: EBookView,
  },
];
