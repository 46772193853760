<template>
  <div class="sticky top-0 z-50 font-[IBM]">
    <div class="bg-white">
      <div class="lg:flex py-3 lg:mt-0">
        <div class="flex flex-none">
          <div class="flex-none">
            <img
              class="ml-2 mt-3 lg:hidden w-[20px]"
              src="@/assets/img/header/hamburguer-menu-icon.svg"
              alt="Icone do Menu"
              @click="toggle()"
            />
          </div>
          <div class="grow">
            <MainLogo class="lg:ml-10 lg:mt-0 lg:mb-3 flex justify-center" />
          </div>
        </div>
        <div class="grow hidden lg:block">
          <div class="flex justify-center">
            <div
              class="font-['Barlow'] mt-3 mx-2"
              v-for="item of menu"
              :key="item"
            >
              <router-link v-if="item.isRouter" :to="item.link"
                >{{ item.label }}
              </router-link>
              <a v-else :href="item.link" target="_blank">{{ item.label }}</a>
            </div>
          </div>
        </div>
        <div class="flex-none hidden lg:block">
          <div class="flex">
            <div class="mt-4 mx-2">
              <a
                class="bg-[#5095FD] text-white font-bold rounded-md px-10 mr-10 py-2 font-['Roboto']"
                :href="this.clientPanel"
                target="blank"
                >Entrar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="hidden lg:block h-[0px] border-solid border-1 border-gray" />

    <div
      :class="{
        'flex flex-col top-14 z-50 absolute w-full bg-white py-10': status,
        hidden: !status,
      }"
    >
      <div class="mt-3 mx-2">
        <div v-for="item of menu" :key="item">
          <router-link v-if="item.isRouter" :to="item.link" @click="toggle()">{{
            item.label
          }}</router-link>
          <a v-else :href="item.link" target="blank">{{ item.label }}</a>
        </div>
        <a :href="this.clientPanel">Painel do Cliente</a>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from "@/components/MainLogo.vue";

export default {
  components: {
    MainLogo,
  },
  data() {
    return {
      status: false,
      menu: [
        {
          label: "Solicitação de Dados",
          link: "/solicitacao-dos-titulares",
          isRouter: true,
        },
        { label: "Preços", link: "/precos", isRouter: true },
        { label: "Quem Somos", link: "/quem-somos", isRouter: true },
        { label: "Blog", link: "https://blog.bcompliance.com.br/", isRouter: false },
        { label: "Clientes", link: "/empresas", isRouter: true },
      ],
    };
  },
  methods: {
    toggle() {
      this.status = !this.status;
    },
    goTo(link) {
      this.$router.push({ path: link });
      // window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style>
.line {
  height: 0px;
  border: none;
  border-top: 1px solid black;
}
</style>
