<template>
  <div class="flex flex-col">
    <div class="flex">
      <img
        class="lg:mt-2 w-[30px] md:h-[35px] md:w-[36px] lg:h-[32px] lg:w-[31px] ml-0"
        src="@/assets/img/common/logo.svg"
        alt="Logo BCompliance"
      />
      <div
        class="ml-2 lg:mt-2 text-[24px] md:text-[28px] lg:text-2xl font-['Museo']"
      >
        <router-link
          to="/"
          class="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500"
          >BCompliance&Law</router-link
        >
      </div>
    </div>
    <div class="text-black text-left text-[10px] mt-5" v-if="footer">
      <p>BC TECNOLOGIA DA INFORMAÇÃO LTDA.</p>
      <p>CNPJ: 41.507.721/0001-70</p>
      <a target="_blank" href="https://goo.gl/maps/fuEGa7V8b8dRRHaR7"
        ><p
          class="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500"
        >
          Rua Eugenio Rabello, nº 98
        </p>
        <p
          class="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500"
        >
          Jardim Embaixador, Sorocaba-SP
        </p>
        <p
          class="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500"
        >
          CEP: 18040-436
        </p></a
      >

      <p>
        <a target="_blank"
          class="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500"
          href="tel:+5515998341602"
          >(15) 998341602</a
        >
      </p>
      <p>
        <a target="_blank"
          class="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500"
          href="mailto:contato@bcompliance.com.br"
          >contato@bcompliance.com.br</a
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    footer: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
