import { createRouter, createWebHistory } from "vue-router";

import { routes } from './routes.js'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass:
    "text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-blue-500 ",
    scrollBehavior(to) {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth'
        };
      } else {
        return {
          top: 0,
          behavior: 'smooth'
        };
      }
    }
});

export default router;
